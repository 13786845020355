// import Footer from 'layout/Footer/Footer';

const DefaultFooter = () => {
	return (
		<>
			{/* <div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						<Popovers
							title='Footer.tsx'
							desc={<code>src/pages/_layout/_footers/DefaultFooter.tsx</code>}>
							Footer
						</Popovers>
						<code className='ps-3'>DefaultFooter.tsx</code>
					</div>
				</div>
			</div> */}
		</>
	);
};

export default DefaultFooter;
