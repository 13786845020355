import Icon from 'components/icon/Icon';
import { TIcons } from 'type/icons-type';

interface ILeftIconBoxProps {
	bgColor: string;
	icon: TIcons;
}
export const LeftIconBox = ({ bgColor, icon }: ILeftIconBoxProps) => {
	return (
		<div className={`${bgColor} d-flex align-items-center justify-content-center w-25 h-100`}>
			<Icon icon={icon} size='2x' />
		</div>
	);
};
