import { RouteProps } from 'react-router-dom';
import { generalPagesMenu } from 'menu';
import DefaultAside from 'pages/_layout/_asides/DefaultAside';

const asides: RouteProps[] = [
	{ path: generalPagesMenu.login.path, element: null },
	{ path: generalPagesMenu.firstTimeLogin.path, element: null },
	{ path: generalPagesMenu.signUp.path, element: null },
	{ path: generalPagesMenu.blank.path, element: null },
	{ path: '*', element: <DefaultAside /> },
];

export default asides;
