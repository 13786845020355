import FormGroup, { IFormGroupProps } from 'components/bootstrap/forms/FormGroup';
import { ReactElement } from 'react';

interface IPCSOFormGroupProps extends IFormGroupProps {
	children: ReactElement | ReactElement[];
}
export const PCSOFormGroup = ({ children, ...props }: IPCSOFormGroupProps) => {
	return (
		<FormGroup className='d-flex flex-column flex-md-row align-items-start mb-2' {...props}>
			{children}
		</FormGroup>
	);
};
