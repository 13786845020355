import Card, {
	CardActions,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from 'components/bootstrap/Card';

enum StatusId {
	active = 1,
	inactive = 2,
	locked = 3,
}

interface IPCSOCardProps {
	setModalStatus: (status: boolean) => void;
	title: string;
	subtitle?: string;
	userStatus: number | string;
}
export const PCSOCard = ({ setModalStatus, title, subtitle, userStatus }: IPCSOCardProps) => {
	let bgColor;
	let primaryTextColor;
	let secondaryTextColor;
	let statusText;

	if (userStatus === StatusId.active) {
		bgColor = 'bg-white';
		primaryTextColor = 'text-black';
		secondaryTextColor = 'text-muted';
		statusText = 'Active';
	} else if (userStatus === StatusId.inactive) {
		bgColor = 'bg-danger bg-gradient';
		primaryTextColor = 'text-white';
		secondaryTextColor = 'text-light';
		statusText = 'Inactive';
	} else if (userStatus === StatusId.locked) {
		bgColor = 'bg-warning bg-gradient';
		primaryTextColor = 'text-black';
		secondaryTextColor = 'text-black';
		statusText = 'Locked';
	}

	return (
		<Card
			className='cursor-pointer'
			borderColor='light'
			borderSize={1}
			shadow='none'
			onClick={() => setModalStatus(true)}>
			<CardHeader className={bgColor} style={{ fontFamily: 'Poppins' }}>
				<CardLabel>
					<CardTitle className={`fs-4 fw-semibold ${primaryTextColor}`}>{title}</CardTitle>
					{subtitle && <CardSubTitle className={secondaryTextColor}>{subtitle}</CardSubTitle>}
				</CardLabel>
				<CardActions>
					<span className={`fs-6 ${secondaryTextColor}`}>{statusText}</span>
				</CardActions>
			</CardHeader>
		</Card>
	);
};
