import AuthContext from 'contexts/authContext';
import React, { lazy, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
// import { contents, contents2 } from 'routes/contentRoutes';
import { contents } from 'routes/contentRoutes';

const PAGE_404 = lazy(() => import('pages/presentation/auth/Page404'));
const ContentRoutes = () => {
	const userRole = useContext(AuthContext);
	const [moduleList, setModuleList] = useState(contents);

	const permissionsList = localStorage.getItem('permissions')?.split(',');

	useEffect(() => {
		const list = [];
		//@ts-ignore
		if (
			permissionsList !== null &&
			//@ts-ignore
			permissionsList !== 'undefined' &&
			//@ts-ignore
			permissionsList !== ''
		) {
			if (list?.length === 0) {
				list.push(contents[0]);
				list.push(contents[2]);
			}
			//@ts-ignore
			for (let i = 0; i < permissionsList?.length; i++) {
				//@ts-ignore
				for (let j = 0; j < contents?.length; j++) {
					// @ts-ignore
					if (permissionsList![i] === contents[j].id) {
						if (permissionsList![i] === 'VIEW_Users') {
							list.push(contents[1]);
							list.push(contents[j]);
							break;
						} else {
							list.push(contents[j]);
							break;
						}
					}
				}
			}
			setModuleList(list);
		}
	}, [permissionsList?.length, contents?.length]);
	return (
		// <Routes>
		// 	{/* @ts-ignore */}
		// 	{userRole.role === 'System Administrator' ||
		// 	//@ts-ignore
		// 	localStorage.getItem('roles') === 'System Administrator' ||
		// 	localStorage.getItem('roleIds') === '2'
		// 		? contents.map((page) => (
		// 				// eslint-disable-next-line react/jsx-props-no-spreading
		// 				<Route key={page.path} {...page} />
		// 		  ))
		// 		: contents2.map((page) => (
		// 				// eslint-disable-next-line react/jsx-props-no-spreading
		// 				<Route key={page.path} {...page} />
		// 		  ))}
		// 	<Route path='*' element={<PAGE_404 />} />
		// </Routes>
		<Routes>
			{moduleList.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
