import PropTypes from 'prop-types';
import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	userData: object;
	hasSystemPermission: boolean;
	hasAreaPermission: boolean;
	hasOutletPermission: boolean;
	hasAssistantPermission: boolean;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider = ({ children }: IAuthContextProviderProps) => {
	const [user, setUser] = useState<string>(localStorage.getItem('facit_authUsername') || '');
	const [userData, setUserData] = useState({
		name: '',
		roleIds: '',
		roles: '',
		accessToken: '',
	});

	useEffect(() => {
		localStorage.setItem('facit_authUsername', user);
	}, [user]);

	useEffect(() => {
		if (
			localStorage.getItem('name') === null ||
			localStorage.getItem('roles') === null ||
			localStorage.getItem('roleIds') === null ||
			localStorage.getItem('accessToken') === null
		) {
			setUserData(userData);
		} else {
			setUserData({
				name: localStorage.getItem('name')!,
				roleIds: localStorage.getItem('roleIds')!,
				roles: localStorage.getItem('roles')!,
				accessToken: localStorage.getItem('accessToken')!,
			});
		}
	}, [user]);

	const value = useMemo(
		() => ({
			user,
			setUser,
			userData,
		}),
		[user, userData],
	);
	// @ts-ignore
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
