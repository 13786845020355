import Button from 'components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from 'components/bootstrap/Card';
import { ReactNode } from 'react';

interface IPageCardProps {
	setModalStatus: (status: boolean) => void;
	resetField: () => void;
	children: ReactNode;
	buttonText?: string;
	cardLabelIcon?: string;
	cardTitle: string;
	hasAddModal?: boolean;
	hasForbiddenModal?: boolean;
}
export const PageCard = ({
	setModalStatus,
	resetField,
	children,
	buttonText,
	cardLabelIcon,
	cardTitle,
	hasAddModal = true,
	hasForbiddenModal = false,
}: IPageCardProps) => {
	return (
		<Card stretch className='border border-2'>
			<CardHeader className='bg-light'>
				<CardLabel icon={cardLabelIcon} iconColor='info'>
					<CardTitle>{cardTitle}</CardTitle>
				</CardLabel>
				{hasAddModal && (
					<CardActions>
						<Button
							color='info'
							icon='Add'
							onClick={() => {
								setModalStatus(true);
								resetField();
							}}>
							{buttonText}
						</Button>
					</CardActions>
				)}
				{hasForbiddenModal && (
					<CardActions>
						<Button
							color='danger'
							icon='DoDisturbOn'
							onClick={() => {
								setModalStatus(true);
								resetField();
							}}>
							{buttonText}
						</Button>
					</CardActions>
				)}
			</CardHeader>
			<CardBody>{children}</CardBody>
		</Card>
	);
};
