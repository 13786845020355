import axios, { AxiosInstance } from 'axios';
import { generalPagesMenu } from 'menu';
import { showNoti } from 'pages/presentation/AreaGroupsPage';
import { ApiRequestConfig } from 'type/api-type';

export const localAxiosParams = {
	baseURL: process.env.REACT_APP_JSON_SERVER,
};

export const serverAxiosParams = {
	baseURL: process.env.REACT_APP_API_URL,
};

export const API_TOKEN = localStorage.getItem('accessToken');

// Create axios instance with default params
const localAxiosInstance = axios.create(localAxiosParams);
export const severAxiosInstance = axios.create(serverAxiosParams);

// Add a request interceptor to add the access token to the headers
severAxiosInstance.interceptors.request.use(
	(config) => {
		const accessToken = localStorage.getItem('accessToken');
		if (accessToken) {
			config.headers!.Authorization = `Bearer ${accessToken}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);
severAxiosInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error?.response?.data?.data?.status === 401) {
			localStorage.clear();
			window.location.href = `/${generalPagesMenu.login.path}`;
			showNoti('Security Logout', '', 'danger');
		}
		// Pass the error to the component
		return Promise.reject(error);
	},
);

// Main api function
const api = (axiosInstance: AxiosInstance) => {
	return {
		get: (url: string, config: ApiRequestConfig = {}) => axiosInstance.get(url, config),
		delete: (url: string, config: ApiRequestConfig = {}) => axiosInstance.delete(url, config),
		post: (url: string, body: unknown, config: ApiRequestConfig = {}) =>
			axiosInstance.post(url, body, config),
		put: (url: string, body: unknown, config: ApiRequestConfig = {}) =>
			axiosInstance.put(url, body, config),
		patch: (url: string, body: unknown, config: ApiRequestConfig = {}) =>
			axiosInstance.patch(url, body, config),
	};
};

export const localApi = api(localAxiosInstance);
export const serverApi = api(severAxiosInstance);
