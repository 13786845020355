import Input, { IInputProps } from 'components/bootstrap/forms/Input';
import { useController } from 'react-hook-form';

export interface PCSOInputProps extends IInputProps {
	name: string;
	control?: any;
	fontFamily?: string;
	placeholder?: string;
	onKeyDown?: any;
	type?: any;
}
export const PCSOInput = ({
	name,
	control,
	fontFamily,
	placeholder,
	onKeyDown,
	type,
	...props
}: PCSOInputProps) => {
	const { field } = useController({
		name,
		control,
	});

	return (
		<Input
			{...field}
			{...props}
			placeholder={placeholder}
			style={{
				fontFamily: fontFamily || 'Poppins',
				letterSpacing: fontFamily ? '0.5px' : undefined,
				fontWeight: fontFamily ? '600' : undefined,
			}}
			onKeyDown={onKeyDown}
			type={type}
		/>
	);
};
