import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SidebarCurrentDraw } from 'components/pcso/Sidebar/SidebarCurrentDraw';
import ThemeContext from 'contexts/themeContext';
import Aside, { AsideBody, AsideHead } from 'layout/Aside/Aside';
import Brand from 'layout/Brand/Brand';
import Navigation from 'layout/Navigation/Navigation';
import { pcsoSidebarMenu, pcsoSidebarSettings, pcsoRootSidebarSettings } from 'menu';
import { getRefPermission } from 'api/baseApi';
import { QUERY_KEYS } from 'constants/constants';
import { useQuery } from '@tanstack/react-query';
import { contents } from 'routes/contentRoutes';
import { useNavigate } from 'react-router-dom';
import useAsideTouch from 'hooks/useAsideTouch';

const { PERMISSION_LISTING } = QUERY_KEYS;
const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [doc, setDoc] = useState(localStorage.getItem('facit_asideDocStatus') === 'true' || false);

	const { t } = useTranslation(['translation', 'menu']);

	const permissionQuery = useQuery([PERMISSION_LISTING], getRefPermission, {
		enabled: false,
	});

	const permissionsList = localStorage.getItem('permissions')?.split(',');
	const [menuListMap, setMenuListMap] = useState(pcsoSidebarMenu);
	const [setingListMap, setSettingListMap] = useState(pcsoSidebarSettings);

	useEffect(() => {
		permissionQuery.refetch();
	}, []);
	useEffect(() => {
		const mapList = {};
		const settingList = {};
		//@ts-ignore
		if (permissionsList !== null && permissionsList !== undefined) {
			//@ts-ignore
			for (let i = 0; i < permissionsList?.length; i++) {
				const pageName = permissionQuery?.data
					//@ts-ignore
					?.find((state) => state.code === permissionsList[i])
					?.description.toLowerCase();
				//@ts-ignore
				if (
					//@ts-ignore
					pcsoSidebarMenu[
						permissionQuery.data
							//@ts-ignore
							?.find((state) => state.code === permissionsList[i])
							?.description.toLowerCase()
					] !== undefined
				) {
					if (mapList) {
						//@ts-ignore
						mapList['menu'] = pcsoSidebarMenu['menu'];
						//@ts-ignore
						mapList[pageName] = pcsoSidebarMenu[pageName];
					} else {
						//@ts-ignore
						mapList[pageName] = pcsoSidebarMenu[pageName];
					}
				} else if (
					//@ts-ignore
					pcsoSidebarSettings[
						permissionQuery?.data
							//@ts-ignore
							?.find((state) => state.code === permissionsList[i])
							?.description.toLowerCase()
							.replace(' ', '')
					] !== undefined
				) {
					const pageName = permissionQuery?.data
						//@ts-ignore
						?.find((state) => state.code === permissionsList[i])
						?.description.toLowerCase()
						.replace(' ', '');
					if (mapList) {
						//@ts-ignore
						mapList['settings'] = pcsoSidebarSettings['settings'];
						//@ts-ignore
						mapList[pageName] = pcsoSidebarSettings[pageName];
					} else {
						//@ts-ignore
						mapList[pageName] = pcsoSidebarSettings[pageName];
					}
				}
				//@ts-ignore
				setMenuListMap(mapList);
				//@ts-ignore
				setSettingListMap(settingList);
			}
		}
	}, [permissionsList?.length, permissionQuery?.data?.length]);

	return (
		<Aside>
			<AsideHead>
				<Brand
					asideStatus={asideStatus}
					setAsideStatus={setAsideStatus}
					//@ts-ignore
					navigateLink={
						//@ts-ignore
						pcsoSidebarMenu[
							permissionQuery?.data
								?.find(
									//@ts-ignore
									(state) =>
										state.code ===
										//@ts-ignore
										permissionsList[1],
								)
								.description.toLowerCase()
						]?.path
					}
				/>
			</AsideHead>
			<AsideBody>
				{!doc && (
					<>
						<Navigation menu={menuListMap} id='aside-pcso-sidebar-menu' />
						<Navigation menu={setingListMap} id='aside-pcso-sidebar-settings' />
					</>
				)}
				{asideStatus && <SidebarCurrentDraw />}
				{/* <SidebarCurrentDraw /> */}
			</AsideBody>
		</Aside>
	);
};

export default DefaultAside;
