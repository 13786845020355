import { TourProvider } from '@reactour/tour';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import Lottie from 'react-lottie-player';
import { ReactNotifications } from 'react-notifications-component';
import { ToastProvider } from 'react-toast-notifications';
import { useFullscreen } from 'react-use';
import loading from 'assets/img/loading/loading.json';
import COLORS from 'common/data/enumColors';
import { Toast, ToastContainer } from 'components/bootstrap/Toasts';
import ThemeContext from 'contexts/themeContext';
import { getOS } from 'helpers/helpers';
import useDarkMode from 'hooks/useDarkMode';
import AsideRoutes from 'layout/Aside/AsideRoutes';
import Portal from 'layout/Portal/Portal';
import Wrapper from 'layout/Wrapper/Wrapper';
import steps, { styles } from 'steps';
import * as Sentry from '@sentry/react';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

if (process.env.NODE_ENV === 'production') {
	console.log = () => {};
	console.error = () => {};
	console.debug = () => {};
}

const App = () => {
	getOS();

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
		white: COLORS.WHITE.code,
	};
	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	// @ts-ignore
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	// const defaultOptions = {
	// 	loop: true,
	// 	autoplay: true,
	// 	animationData: loading,
	// 	renderer: 'svg',
	// };

	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={theme}>
				<ToastProvider components={{ ToastContainer, Toast }}>
					<TourProvider steps={steps} styles={styles} showNavigation={false} showBadge={false}>
						<div
							ref={ref}
							className='app'
							style={{
								backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
								zIndex: fullScreenStatus ? 1 : undefined,
								overflow: fullScreenStatus ? 'scroll' : undefined,
							}}>
							{/* <AsideRoutes /> */}
							{/* <Suspense fallback={<p>Loading...</p>}>
								<AsideRoutes />
								<Wrapper />
							</Suspense> */}

							<Suspense
								fallback={
									<Lottie
										loop
										animationData={loading}
										play
										style={{
											width: window.innerWidth / 2,
											height: window.innerHeight / 2,
											justifyContent: 'center',
											alignSelf: 'center',
											marginTop: 150,
										}}
									/>
								}>
								<AsideRoutes />
								<Wrapper />
							</Suspense>
						</div>
						<Portal id='portal-notification'>
							<ReactNotifications />
						</Portal>
					</TourProvider>
				</ToastProvider>
			</ThemeProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
};

export default App;
