/**
 * If you need "All" for your dropdown as the default,
 * you need to pass `hasAll` to true,
 * and a string to `allTitle`
 * else, just pass `arr` is enough
 * @param arr
 * @param hasAll
 * @param allTitle
 * @returns
 */
export function selectValues(
	arr: any,
	hasAll: boolean = false,
	allTitle?: string,
	hasDeleted?: boolean,
	value?: string,
	code?: string,
) {
	// let values = arr?.map((item: any) => {
	// 	return {
	// 		text: item.name !== null ? item.name : 'No Selection',
	// 		value: item.id !== null && item.id.toString(),
	// 	};
	// });
	let values = arr?.map((item: any) => {
		return {
			text: item.name || item.code,
			value: item.id?.toString(),
		};
	});

	let result = [];

	if (
		values?.length === 0 ||
		values === null ||
		values === undefined ||
		values[0].value === undefined ||
		values[0].text === undefined
	) {
		result = [{ text: `No Selection`, value: '0' }];
	} else if (allTitle === `Not Selected`) {
		result = [{ text: `Not Selected`, value: '0' }, ...values];
	} else if (hasAll) {
		result = [{ text: `All ${allTitle}`, value: '' }, ...values];
	} else if (hasDeleted) {
		result = [{ text: `${allTitle}`, value: value }, ...values];
	} else {
		result = values;
	}

	return result;
}
