import { useState } from 'react';

const useModalStatus = () => {
	const [addModalStatus, setAddModalStatus] = useState<boolean>(false);
	const [editModalStatus, setEditModalStatus] = useState<boolean>(false);
	const [confirmModalStatus, setConfirmModalStatus] = useState<boolean>(false);
	const [rowModalStatus, setRowModalStatus] = useState<boolean>(false);
	const [addLevelModalStatus, setAddLevelModalStatus] = useState<boolean>(false);
	const [editLevelModalStatus, setEditLevelModalStatus] = useState<boolean>(false);

	return {
		addModalStatus,
		setAddModalStatus,
		editModalStatus,
		setEditModalStatus,
		confirmModalStatus,
		setConfirmModalStatus,
		rowModalStatus,
		setRowModalStatus,
		addLevelModalStatus,
		setAddLevelModalStatus,
		editLevelModalStatus,
		setEditLevelModalStatus,
	};
};

export default useModalStatus;
