import { ErrorMessage } from '@hookform/error-message';
import { FormErrorMsg } from 'components/pcso/FormErrorMsg';
import { PCSOFormGroup } from 'components/pcso/form/PCSOFormGroup';
import { PCSOFormLabel } from 'components/pcso/form/PCSOFormLabel';
import { PCSOInputDisabled } from 'components/pcso/form/PCSOInputDisabled';
import { PCSOSelect } from 'components/pcso/form/PCSOSelect';
import { PCSOSelectError } from 'components/pcso/form/PCSOSelectError';
import { PCSOSelectLoading } from 'components/pcso/form/PCSOSelectLoading';
import { PCSOSelectNoLocation } from 'components/pcso/form/PCSOSelectNoLocation';

interface FormSelectInputProps {
	query?: {
		isLoading: boolean;
		isRefetching: boolean;
		isError: boolean;
		isSuccess: boolean;
		data: any;
	};
	label: string;
	name: string;
	list?: any;
	errors?: any;
	control: any;
	isRequired?: boolean;
	value?: any;
	noSelectionText?: string;
	disabled?: boolean;
	dropdownWithoutQuery?: boolean;
}

export const FormSelectInput = ({
	query,
	control,
	name,
	label,
	list,
	errors,
	isRequired,
	value,
	noSelectionText,
	disabled = false,
	dropdownWithoutQuery = false,
}: FormSelectInputProps) => {
	return (
		<PCSOFormGroup>
			<PCSOFormLabel isRequired={isRequired} labelText={label} />
			{query ? (
				<div className='w-100'>
					{(query.isLoading || query.isRefetching) && (
						<PCSOSelectLoading ariaLabel={`${label} Select`} />
					)}
					{query.isError && <PCSOSelectError ariaLabel={`${label} Select`} />}
					{query.isSuccess &&
						!query.isRefetching &&
						(query.data?.length !== 0 ? (
							<PCSOSelect
								control={control}
								list={list}
								name={name}
								placeholder={`Choose ${label.toLowerCase()}`}
								disabled={disabled}
							/>
						) : (
							<PCSOSelectNoLocation
								control={control}
								name={name}
								className='w-100'
								text={noSelectionText}
							/>
						))}
					{errors && (
						<ErrorMessage
							errors={errors}
							name={name}
							render={({ message }) => <FormErrorMsg msg={message} />}
						/>
					)}
				</div>
			) : dropdownWithoutQuery ? (
				<div className='w-100'>
					<PCSOSelect
						control={control}
						list={list}
						name={name}
						placeholder={`Choose ${label.toLowerCase()}`}
						disabled={disabled}
					/>
				</div>
			) : (
				<div className='w-100'>
					<PCSOInputDisabled control={control} name={name} value={value} />
				</div>
			)}
		</PCSOFormGroup>
	);
};
