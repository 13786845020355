import Label from 'components/bootstrap/forms/Label';

interface IPCSOFormLabelProps {
	isRequired?: boolean;
	labelText: string;
}
export const PCSOFormLabel = ({ isRequired = false, labelText }: IPCSOFormLabelProps) => {
	return (
		<div className='d-flex'>
			<Label
				title={labelText}
				isHidden={false}
				className='m-0 fw-semibold'
				style={{ minWidth: '150px' }}>
				{labelText}
				{isRequired ? (
					<Label className='m-0 text-danger'>*</Label>
				) : (
					<Label className='m-0 text-white'>*</Label>
				)}
			</Label>
		</div>
	);
};
