import { lazy } from 'react';

import { generalPagesMenu, pcsoSidebarMenu, pcsoSidebarSettings } from 'menu';

const GENERAL = {
	LOGIN: lazy(() => import('pages/presentation/auth/Login')),
	FIRST_TIME_LOGIN: lazy(() => import('pages/presentation/auth/FirstTimeLogin')),
	USER_TRANSACTION_HISTORY: lazy(() => import('pages/presentation/UserTransactionHistoryPage')),
};

const MENU = {
	DASHBOARD: lazy(() => import('pages/presentation/DashboardPage')),
	TRANSACTIONS: lazy(() => import('pages/presentation/TransactionPage')),
	REPORTS: lazy(() => import('pages/presentation/ReportsPage')),
	ADJUSTMENTS: lazy(() => import('pages/presentation/AdjustmentsPage')),
	APPROVAL: lazy(() => import('pages/presentation/ApprovalPage')),
};

const SETTINGS = {
	MERCHANTS: lazy(() => import('pages/presentation/MerchantsPage')),
	AREA_GROUPS: lazy(() => import('pages/presentation/AreaGroupsPage')),
	AREA_LOCATIONS: lazy(() => import('pages/presentation/AreaLocationsPage')),
	OUTLETS: lazy(() => import('pages/presentation/OutletsPage')),
	USERS: lazy(() => import('pages/presentation/UsersPage')),
	PLAYERS: lazy(() => import('pages/presentation/PlayersPage')),
	WORKERS: lazy(() => import('pages/presentation/WorkersPage')),
	CONFIGURATIONS: lazy(() => import('pages/presentation/ConfigurationsPage')),
	INTEGRATION_MANAGEMENT: lazy(() => import('pages/presentation/IntegrationManagementPage')),
};

const presentation = [
	{
		id: '',
		path: generalPagesMenu.login.path,
		element: <GENERAL.LOGIN />,
	},
	{
		id: '',
		path: generalPagesMenu.usersTransactionHistory.path,
		element: <GENERAL.USER_TRANSACTION_HISTORY />,
	},
	{
		id: '',
		path: generalPagesMenu.firstTimeLogin.path,
		element: <GENERAL.FIRST_TIME_LOGIN />,
	},

	/**
	 * MENU
	 */
	{
		id: 'VIEW_Dashboard',
		path: pcsoSidebarMenu.dashboard.path,
		element: <MENU.DASHBOARD />,
	},
	{
		id: 'VIEW_Transactions',
		path: pcsoSidebarMenu.transactions.path,
		element: <MENU.TRANSACTIONS />,
	},
	{
		id: 'VIEW_Reports',
		path: pcsoSidebarMenu.reports.path,
		element: <MENU.REPORTS />,
	},
	{
		id: 'VIEW_Adjustments',
		path: pcsoSidebarMenu.adjustments.path,
		element: <MENU.ADJUSTMENTS />,
	},
	{
		id: 'VIEW_Approval_Center',
		path: pcsoSidebarMenu['approval center'].path,
		element: <MENU.APPROVAL />,
	},

	/**
	 * SETTINGS
	 */
	{
		id: 'VIEW_Merchants',
		path: pcsoSidebarSettings.merchants.path,
		element: <SETTINGS.MERCHANTS />,
	},
	{
		id: 'VIEW_Area_Groups',
		path: pcsoSidebarSettings.areagroups.path,
		element: <SETTINGS.AREA_GROUPS />,
	},
	{
		id: 'VIEW_Area_Locations',
		path: pcsoSidebarSettings.arealocations.path,
		element: <SETTINGS.AREA_LOCATIONS />,
	},
	{
		id: 'VIEW_Outlets',
		path: pcsoSidebarSettings.outlets.path,
		element: <SETTINGS.OUTLETS />,
	},
	{
		id: 'VIEW_Users',
		path: pcsoSidebarSettings.users.path,
		element: <SETTINGS.USERS />,
	},
	{
		id: 'VIEW_Players',
		path: pcsoSidebarSettings.players.path,
		element: <SETTINGS.PLAYERS />,
	},
	{
		id: 'VIEW_Workers',
		path: pcsoSidebarSettings.workers.path,
		element: <SETTINGS.WORKERS />,
	},
	{
		id: 'VIEW_Configurations',
		path: pcsoSidebarSettings.configurations.path,
		element: <SETTINGS.CONFIGURATIONS />,
	},
	{
		id: 'VIEW_Integration_Management',
		path: pcsoSidebarSettings.integrationmanagement.path,
		element: <SETTINGS.INTEGRATION_MANAGEMENT />,
	},
];

// const presentation = [
// 	{
// 		path: generalPagesMenu.login.path,
// 		element: <GENERAL.LOGIN />,
// 	},
// 	{
// 		path: generalPagesMenu.usersTransactionHistory.path,
// 		element: <GENERAL.USER_TRANSACTION_HISTORY />,
// 	},

// 	/**
// 	 * MENU
// 	 */
// 	{
// 		path: pcsoSidebarMenu.dashboard.path,
// 		element: <MENU.DASHBOARD />,
// 	},
// 	{
// 		path: pcsoSidebarMenu.transactions.path,
// 		element: <MENU.TRANSACTIONS />,
// 	},
// 	{
// 		path: pcsoSidebarMenu.reports.path,
// 		element: <MENU.REPORTS />,
// 	},
// 	{
// 		path: pcsoSidebarMenu.adjustments.path,
// 		element: <MENU.ADJUSTMENTS />,
// 	},
// ];
// const presentation2 = [
// 	/**
// 	 * SETTINGS
// 	 */
// 	{
// 		path: pcsoSidebarSettings.areaGroups.path,
// 		element: <SETTINGS.AREA_GROUPS />,
// 	},
// 	{
// 		path: pcsoSidebarSettings.areaLocations.path,
// 		element: <SETTINGS.AREA_LOCATIONS />,
// 	},
// 	{
// 		path: pcsoSidebarSettings.outlets.path,
// 		element: <SETTINGS.OUTLETS />,
// 	},
// 	{
// 		path: pcsoSidebarSettings.users.path,
// 		element: <SETTINGS.USERS />,
// 	},
// 	{
// 		path: pcsoSidebarSettings.workers.path,
// 		element: <SETTINGS.WORKERS />,
// 	},
// 	{
// 		path: pcsoSidebarSettings.configurations.path,
// 		element: <SETTINGS.CONFIGURATIONS />,
// 	},
// ];

// const presentation3 = [
// 	/**
// 	 * SETTINGS
// 	 */
// 	{
// 		path: pcsoSidebarSettings.configurations.path,
// 		element: <SETTINGS.CONFIGURATIONS />,
// 	},
// ];

// export const contents = [...presentation, ...presentation2];
// export const contents2 = [...presentation, ...presentation3];

export const contents = [...presentation];
// const contents =
// 	localStorage.getItem('roles') === 'System Administrator' ||
// 	localStorage.getItem('roles') === 'Root'
// 		? [...presentation, ...presentation2]
// 		: [...presentation];
// function getContents() {
// 	const userData = useContext(AuthContext);
// 	return userData.role === 'System Administrator'
// 		? [...presentation]
// 		: [...presentation, ...presentation2];
// }

// const contents = getContents();

// export default contents;
