import { PCSOSelect } from 'components/pcso/form/PCSOSelect';

interface IProps {
	control: any;
	name: string;
	className?: string;
	disabled?: boolean;
	text?: string;
}

export const PCSOSelectNoLocation = ({
	control,
	name,
	className,
	disabled,
	text = 'No Location',
}: IProps) => {
	return (
		<PCSOSelect
			disabled={disabled}
			control={control}
			list={[{ text, value: '' }]}
			name={name}
			className={className}
		/>
	);
};
