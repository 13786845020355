import Button from 'components/bootstrap/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'components/bootstrap/Modal';
import Icon from 'components/icon/Icon';

interface IModalConfirmProps {
	handleDelete: () => void;
	setModalStatus: (status: boolean) => void;
	modalStatus: boolean;
	formId?: string;
	isStaticBackdrop?: boolean;
	disableBtn?: boolean;
	isLoading?: boolean;
}
export const ModalConfirm = ({
	handleDelete,
	setModalStatus,
	modalStatus,
	formId,
	isStaticBackdrop,
	disableBtn = false,
	isLoading = false,
}: IModalConfirmProps) => {
	return (
		<Modal
			isStaticBackdrop={isStaticBackdrop}
			isAnimation={false}
			id='confirmDeleteModal'
			setIsOpen={setModalStatus}
			isOpen={modalStatus}
			isCentered={true}
			titleId='confirmDeleteModal'>
			<ModalHeader className='bg-danger text-white'>
				<ModalTitle id='confirmDeleteModal' className='fs-4'>
					Delete confirmation
				</ModalTitle>
				<Icon icon='Cancel' size='2x' onClick={() => setModalStatus(false)} />
			</ModalHeader>
			<ModalBody>Are you sure you want to delete?</ModalBody>
			<ModalFooter>
				<Button
					isDisable={disableBtn}
					type='submit'
					color='danger'
					icon='Delete'
					form={formId}
					onClick={handleDelete}>
					{isLoading ? 'Deleting...' : 'Delete'}
				</Button>
				<Button
					isDisable={disableBtn}
					color='light'
					icon='Block'
					onClick={() => setModalStatus(false)}>
					No, thanks
				</Button>
			</ModalFooter>
		</Modal>
	);
};
