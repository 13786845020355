import { useQuery } from '@tanstack/react-query';
import { severAxiosInstance } from 'api/api';
import { getSystemRemainFund } from 'api/baseApi';
import Button, { IButtonProps } from 'components/bootstrap/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'components/bootstrap/Modal';
import Popovers from 'components/bootstrap/Popovers';
import Icon from 'components/icon/Icon';
import AuthContext from 'contexts/authContext';
import dayjs from 'dayjs';
import { addZeros } from 'helpers/addZeros';
import { formatAmount } from 'helpers/formatAmount';
import useDarkMode from 'hooks/useDarkMode';
import Header, { HeaderLeft, HeaderRight } from 'layout/Header/Header';
import { generalPagesMenu } from 'menu';
import { showNoti } from 'pages/presentation/AreaGroupsPage';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTime } from 'react-timer-hook';

// Phase 2
enum PageID {
	P1 = 'PG0100', // Dashboard
	P2 = 'PG0200', // Transactions
	P3 = 'PG0300', // Reports
	P4 = 'PG0400', // Adjustments
	P5 = 'PG0500', // Area Groups
	P6 = 'PG0600', // Area Locations
	P7 = 'PG0700', // Outlets
	P8 = 'PG0800', // Users
	P0801 = 'PG0801', // User Transaction History
	P9 = 'PG0900', // Players
	P10 = 'PG1000', // Workers
	P11 = 'PG1100', // Configurations
	P12 = 'PG1200', // Approval Center
	P13 = 'PG1300', // Merchants
	P14 = 'PG1400', // Integration Management
}

export const DashboardHeader = () => {
	const monthNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	const [modifiedPathname, setModifiedPathname] = useState('DASHBOARD');
	const [pageId, setPageId] = useState(PageID.P1);
	const { darkModeStatus } = useDarkMode();
	const { setUser } = useContext(AuthContext);
	const [confirmModalStatus, setConfirmModalStatus] = useState<boolean>(false);
	const [loginRole, setLoginRole] = useState<string>('');
	const [loginPermissions, setLoginPermissions] = useState<string>('');
	const [accessToken, setAccessToken] = useState<string>('');
	const [thirdParty, setThirdParty] = useState<string>('');
	const role = localStorage.getItem('roles')!;
	const permissions = localStorage.getItem('permissions')!;
	const token = localStorage.getItem('accessToken')!;
	const isThirdParty = localStorage.getItem('isThirdParty')!;
	const refreshTopUp = Boolean(sessionStorage.getItem('topUp'));
	const systemRemainFundQuery = useQuery(
		['systemRemainFund', modifiedPathname],
		getSystemRemainFund,
	);

	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		isLight: darkModeStatus,
		size: 'lg',
	};

	const { i18n } = useTranslation();

	const { pathname } = useLocation();
	const navigate = useNavigate();

	const { seconds, minutes, hours } = useTime({});

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	useEffect(() => {
		const path = pathname.slice(1).toUpperCase();
		if (path.includes('-')) {
			let splittedPath = path.split('-');
			setModifiedPathname(`${splittedPath[0]} ${splittedPath[1]}`);
		} else {
			setModifiedPathname(path);
		}
	}, [pathname]);

	useEffect(() => {
		switch (modifiedPathname) {
			case 'DASHBOARD':
				setPageId(PageID.P1);
				break;
			case 'DASHBOARD/':
				setPageId(PageID.P1);
				break;
			case 'TRANSACTIONS':
				setPageId(PageID.P2);
				break;
			case 'TRANSACTIONS/':
				setPageId(PageID.P2);
				break;
			case 'REPORTS':
				setPageId(PageID.P3);
				break;
			case 'REPORTS/':
				setPageId(PageID.P3);
				break;
			case 'ADJUSTMENTS':
				setPageId(PageID.P4);
				break;
			case 'ADJUSTMENTS/':
				setPageId(PageID.P4);
				break;
			case 'AREA GROUPS':
				setPageId(PageID.P5);
				break;
			case 'AREA GROUPS/':
				setPageId(PageID.P5);
				break;
			case 'AREA LOCATIONS':
				setPageId(PageID.P6);
				break;
			case 'AREA LOCATIONS/':
				setPageId(PageID.P6);
				break;
			case 'OUTLETS':
				setPageId(PageID.P7);
				break;
			case 'OUTLETS/':
				setPageId(PageID.P7);
				break;
			case 'USERS':
				setPageId(PageID.P8);
				break;
			case 'USERS/':
				setPageId(PageID.P8);
				break;
			case 'USERS TRANSACTION':
				setPageId(PageID.P0801);
				break;
			case 'USERS TRANSACTION/':
				setPageId(PageID.P0801);
				break;
			case 'PLAYERS':
				setPageId(PageID.P9);
				break;
			case 'PLAYERS/':
				setPageId(PageID.P9);
				break;
			case 'WORKERS':
				setPageId(PageID.P10);
				break;
			case 'WORKERS/':
				setPageId(PageID.P10);
				break;
			case 'CONFIGURATIONS':
				setPageId(PageID.P11);
				break;
			case 'CONFIGURATIONS/':
				setPageId(PageID.P11);
				break;
			case 'APPROVAL CENTER/':
				setPageId(PageID.P12);
				break;
			case 'MERCHANTS/':
				setPageId(PageID.P13);
				break;
			case 'INTEGRATION MANAGEMENT/':
				setPageId(PageID.P14);
				break;
			default:
				setPageId(PageID.P1);
				break;
		}
	}, [modifiedPathname]);

	useEffect(() => {
		const currentToken = localStorage.getItem('accessToken');
		if (
			localStorage.getItem('name') === null ||
			localStorage.getItem('roles') === null ||
			localStorage.getItem('roleIds') === null ||
			localStorage.getItem('accessToken') === null ||
			localStorage.getItem('permissions') === null
		) {
			if (setUser) {
				setUser('');
			}
			localStorage.clear();
		} else {
			severAxiosInstance.defaults.headers.common.Authorization = 'Bearer ' + currentToken;
		}
	}, [localStorage.getItem('accessToken')]);

	function forceLogout() {
		setLoginRole('');
		setLoginPermissions('');
		setAccessToken('');
		if (setUser) {
			setUser('');
		}
		localStorage.clear();
		navigate(`../${generalPagesMenu.login.path}`);
		showNoti('Security Logout', '', 'danger');
	}

	useEffect(() => {
		if (loginRole === '' || loginPermissions === '' || accessToken === '' || thirdParty === '') {
			setLoginRole(role);
			setLoginPermissions(permissions);
			setAccessToken(token);
			setThirdParty(isThirdParty);
		} else if (
			(loginRole !== '' && loginRole !== role) ||
			(loginPermissions !== '' && loginPermissions !== permissions) ||
			(accessToken !== '' && accessToken !== token) ||
			(thirdParty !== '' && thirdParty !== isThirdParty)
		) {
			setLoginRole('');
			setLoginPermissions('');
			setAccessToken('');
			setThirdParty('');
			forceLogout();
		}
	}, [role, permissions, token]);

	useEffect(() => {
		if (refreshTopUp !== null && refreshTopUp !== undefined && refreshTopUp === true) {
			systemRemainFundQuery.refetch();
			sessionStorage.removeItem('topUp');
		}
	}, [refreshTopUp]);

	useEffect(() => {
		let time = setTimeout(logout, 1000 * 20 * 60);
		function logout() {
			navigate(`../${generalPagesMenu.login.path}`);
			localStorage.clear();
			window.location.reload();
		}

		function resetTimer() {
			clearTimeout(time);
			time = setTimeout(logout, 1000 * 20 * 60);
			// 1000 milliseconds = 1 second
		}
		resetTimer();
		var events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
		window.addEventListener('load', resetTimer, true);
		events.forEach(function (name) {
			document.addEventListener(name, resetTimer, true);
		});
	}, []);

	return (
		<>
			<Header>
				<HeaderLeft className='m-0'>
					<h2 className='fs-3 fw-semibold text-info m-0' style={{ fontFamily: 'Poppins' }}>
						{`${modifiedPathname.replace('/', '')}`}
					</h2>
					<p className='fs-4 m-0' style={{ fontFamily: 'Roboto' }}>
						{pageId}
					</p>
				</HeaderLeft>

				<HeaderRight>
					<div className='row g-3 align-items-center'>
						{/* Server time */}
						<div className='col-auto d-flex flex-row align-items-center border border-1 border-[#D9D9D9] gap-2 ps-4 pe-3 pt-1 pb-1 rounded-5 me-2'>
							<div className='col-auto d-flex flex-column align-items-center'>
								<p
									className='m-0 fw-semibold text-info fs-5 text-center'
									style={{ fontFamily: 'Roboto' }}>
									<span>PHP {formatAmount(systemRemainFundQuery?.data || 0).slice(0, -3)}</span>
								</p>
								<p className='fw-semibold m-0 text-muted' style={{ fontFamily: 'Poppins' }}>
									CREDIT BALANCE
								</p>
							</div>
							<Icon
								icon={'Sync'}
								size='2x'
								className='d-flex flex-end cursor-pointer bg-light p-1 rounded-circle'
								onClick={() => {
									if (!systemRemainFundQuery.isRefetching) {
										systemRemainFundQuery.refetch();
									}
								}}
							/>
						</div>
						{/* Server time */}
						<div className='col-auto d-flex flex-column w-20 align-items-center'>
							<p
								className='m-0 fw-semibold text-info fs-5 text-center'
								style={{ fontFamily: 'Roboto' }}>
								<span>{monthNames[dayjs(new Date()).month()]}</span>
								<span>{dayjs(new Date()).format(' DD ')}</span>
								<span className='fs-5'>{addZeros(hours)}</span>:
								<span className='fs-5'>{addZeros(minutes)}</span>:
								<span className='fs-5'>{addZeros(seconds)}</span>
							</p>
							<p className='fw-semibold m-0 text-muted' style={{ fontFamily: 'Poppins' }}>
								SERVER TIME
							</p>
						</div>
						<div className='col-auto d-flex' />
						{/* User name */}
						<div className='col-auto'>
							<div className='user-info'>
								<div className='user-name d-flex align-items-center fs-5 text-info'>
									{localStorage.getItem('name')}
								</div>
								<div className='user-sub-title fw-normal fs-6'>{localStorage.getItem('roles')}</div>
							</div>
						</div>
						{/* Logout button */}
						<div className='col-auto'>
							<Popovers trigger='hover' desc='Log out'>
								<Button
									{...styledBtn}
									icon='Logout'
									onClick={() => {
										setConfirmModalStatus(true);
									}}
								/>
							</Popovers>
						</div>
					</div>
				</HeaderRight>
			</Header>
			<Modal
				isStaticBackdrop
				isAnimation={false}
				id='LogoutModal'
				setIsOpen={setConfirmModalStatus}
				isOpen={confirmModalStatus}
				isCentered={true}
				titleId='Logout Confirmation'>
				<ModalHeader className={'bg-info text-white'}>
					<ModalTitle id='LogoutModal' className='fs-4'>
						Logout confirmation
					</ModalTitle>
					<Icon icon='Cancel' size='2x' onClick={() => setConfirmModalStatus(false)} />
				</ModalHeader>
				<ModalBody className='d-flex flex-column'>Are you sure you want to log out?</ModalBody>
				<ModalFooter>
					<Button
						color='info'
						onClick={() => {
							localStorage.clear();
							setConfirmModalStatus(false);
							if (setUser) {
								setUser('');
							}
							navigate(`../${generalPagesMenu.login.path}`);
						}}>
						Yes, log me out
					</Button>
					<Button color='light' onClick={() => setConfirmModalStatus(false)}>
						No, stay logged in
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};
