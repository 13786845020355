import { ReactNode, useContext } from 'react';
import ReactDOM from 'react-dom';
import ThemeContext from 'contexts/themeContext';

interface IPortalProps {
	children: ReactNode;
	id?: string;
}
// @ts-ignore
const Portal = ({ id = 'portal-root', children }: IPortalProps) => {
	const { fullScreenStatus } = useContext(ThemeContext);
	const mount = document.getElementById(id || '');
	if (fullScreenStatus) return <>{children}</>;
	if (mount) return ReactDOM.createPortal(children, mount);
	return null;
};

export default Portal;
