import { ILoginRequestBody } from 'type/pcso/api-type';
import { ApiRequestConfig } from 'type/api-type';
import { serverApi } from 'api/api';

const MAIN_URL = '/auth';

// Get and manage access tookens for making secure calls to the APIs
export const postLogin = (body: ILoginRequestBody, config: ApiRequestConfig = {}) =>
	serverApi.post(`${MAIN_URL}/login`, body, config).then((res) => res.data);

// Register new user
// export const postRegister = (body = {}, config: ApiRequestConfig = {}) =>
// 	serverApi.post(`${MAIN_URL}/register`, body, config).then((res) => res.data);

// Get version
export const getVersion = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/version`, config).then((res) => res.data);
