import { useQuery } from '@tanstack/react-query';
import { getCurDrawSmry } from 'api/dashboardApi';
import Icon from 'components/icon/Icon';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { SideMenuBarError } from 'components/pcso/SideMenuBarError';
import { LeftIconBox } from 'components/pcso/Sidebar/LeftIconBox';
import { SummaryDetail } from 'components/pcso/Sidebar/SummaryDetail';
import { SummaryGroup } from 'components/pcso/Sidebar/SummaryGroup';

export const SidebarCurrentDraw = () => {
	const leftIconBgColor = ['bg-primary', 'bg-success', 'bg-info', 'bg-warning'];
	const curDrawSmry = useQuery(['curDrawSmry'], getCurDrawSmry, {
		refetchInterval: 1000 * 5 * 60,
		enabled: false,
	});
	const [lastUpdate, setLastUpdated] = useState('');

	let overallSum = 0;
	if (curDrawSmry.isSuccess) {
		for (const sales of curDrawSmry.data.gameTypeGrossSales) {
			overallSum += Number(sales.totalSales);
		}
	}

	useEffect(() => {
		curDrawSmry.refetch();
	}, []);

	useEffect(() => {
		setLastUpdated(dayjs().format('YYYY-MM-DD HH:mm:ss'));
	}, [curDrawSmry.isRefetching]);

	return (
		<div className='d-flex flex-column gap-2 navigation-item summary'>
			<p className='navigation-title text-uppercase'>Current draw summary</p>
			{curDrawSmry.isSuccess ? (
				<>
					<SummaryGroup>
						<LeftIconBox bgColor='bg-danger' icon='Schedule' />
						<SummaryDetail isDraw={true} title='Draw Date' amount={curDrawSmry.data.date} />
					</SummaryGroup>
					{
						// @ts-ignore
						curDrawSmry.data?.gameTypeGrossSales.map((d, index) => {
							return (
								<SummaryGroup key={d.gameName}>
									<LeftIconBox bgColor={leftIconBgColor[index]} icon='CustomCurrencyRuble' />
									<SummaryDetail
										title={`${d.gameName} Gross Sales`}
										amount={d.totalSales.toFixed(2)}
									/>
								</SummaryGroup>
							);
						})
					}
					<SummaryGroup>
						<LeftIconBox bgColor='bg-warning' icon='CustomCurrencyRuble' />
						<SummaryDetail title='OVERALL Gross Sales' amount={overallSum.toFixed(2)} />
					</SummaryGroup>
					<div className='d-flex'>
						<p className='text-start' style={{ fontSize: '10px', width: '80%' }}>
							Last updated: {curDrawSmry.isSuccess && lastUpdate}
						</p>
						<Icon
							icon={'Sync'}
							size='sm'
							className='d-flex flex-end cursor-pointer'
							onClick={() => {
								curDrawSmry.refetch();
							}}
						/>
					</div>
				</>
			) : curDrawSmry.isError ? (
				<>
					<SummaryGroup>
						<LeftIconBox bgColor='bg-danger' icon='Schedule' />
						<SideMenuBarError />
					</SummaryGroup>
					<SummaryGroup>
						<LeftIconBox bgColor={leftIconBgColor[0]} icon='CustomCurrencyRuble' />
						<SideMenuBarError />
					</SummaryGroup>
					<SummaryGroup>
						<LeftIconBox bgColor={leftIconBgColor[1]} icon='CustomCurrencyRuble' />
						<SideMenuBarError />
					</SummaryGroup>
					<SummaryGroup>
						<LeftIconBox bgColor='bg-warning' icon='CustomCurrencyRuble' />
						<SideMenuBarError />
					</SummaryGroup>
				</>
			) : (
				<>
					<SummaryGroup>
						<LeftIconBox bgColor='bg-danger' icon='Schedule' />
						<SummaryDetail isFetching={true} />
					</SummaryGroup>
					<SummaryGroup>
						<LeftIconBox bgColor={leftIconBgColor[0]} icon='CustomCurrencyRuble' />
						<SummaryDetail isFetching={true} />
					</SummaryGroup>
					<SummaryGroup>
						<LeftIconBox bgColor={leftIconBgColor[1]} icon='CustomCurrencyRuble' />
						<SummaryDetail isFetching={true} />
					</SummaryGroup>
					<SummaryGroup>
						<LeftIconBox bgColor='bg-warning' icon='CustomCurrencyRuble' />
						<SummaryDetail isFetching={true} />
					</SummaryGroup>
				</>
			)}
		</div>
	);
};
