import Card from 'components/bootstrap/Card';
import { PLACEHOLDER_BG } from 'constants/constants';

export const PCSOCardGray2 = () => {
	return (
		<Card
			className='cursor-pointer placeholder-glow'
			borderColor='light'
			borderSize={1}
			shadow='none'
			onClick={() => {}}>
			<div className='placeholder-glow' style={{ fontFamily: 'Poppins' }}>
				<div className='d-flex placeholder-glow p-3 '>
					<span
						className='p-2 placeholder col-4'
						style={{ backgroundColor: PLACEHOLDER_BG }}></span>
					<span
						className='ms-auto p-2 placeholder col-2'
						style={{ backgroundColor: PLACEHOLDER_BG }}></span>
				</div>
			</div>
		</Card>
	);
};
