import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteArea, getAreas, postArea, putArea } from 'api/areaApi';
import { getStatus } from 'api/baseApi';
import { showNotification } from 'components/extras/showNotification';
import Icon from 'components/icon/Icon';
import { ErrorOccured } from 'components/pcso/ErrorOccured';
import { NoResultMsg } from 'components/pcso/NoResultMsg';
import { PCSOCard } from 'components/pcso/PCSOCard';
import { PCSOCardGray2 } from 'components/pcso/PCSOCardGray2';
import { PageCard } from 'components/pcso/PageCard';
import { SpinnerBlack } from 'components/pcso/SpinnerBlack';
import { PCSOSearchInput } from 'components/pcso/form/PCSOSearchInput';
import { FormSelectInput } from 'components/pcso/form/input/FormSelectInput';
import { FormTextInput } from 'components/pcso/form/input/FormTextInput';
import { ModalAdd } from 'components/pcso/modal/ModalAdd';
import { ModalConfirm } from 'components/pcso/modal/ModalConfirm';
import { ModalEdit } from 'components/pcso/modal/ModalEdit';
import { filterData } from 'helpers/filterData';
import { selectValues } from 'helpers/selectValues';
import { trimInput } from 'helpers/trimInput';
import useModalStatus from 'hooks/useModalStatus';
import Page from 'layout/Page/Page';
import PageWrapper from 'layout/PageWrapper/PageWrapper';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { IAreaGroupRequestBody } from 'type/pcso/api-type';
import { TAreaGroupFormProps } from 'type/pcso/areaGroup-type';
import * as yup from 'yup';
import { API_DATA_MAPPING, QUERY_KEYS, TOP_INPUTS } from 'constants/constants';

const { TI_SEARCH_TERM } = TOP_INPUTS;
const { BASE_STATUS, AREA_LISTING } = QUERY_KEYS;
const { FIRST_NAME, USER_CODE, USER_STATUS, ID } = API_DATA_MAPPING;

export function showNoti(header: string, body: string, type?: string) {
	showNotification(
		<span className='d-flex align-items-center'>
			<Icon icon='Info' size='lg' className='me-1' />
			<span>{header}</span>
		</span>,
		body,
		type,
	);
}
const antiSymbol = /[\^\$\.\|\?\*\+\(\)\[\]\{\}\\]/;
const schema = yup
	.object({
		[FIRST_NAME]: yup
			.string()
			.trim('The Area First Name cannot include leading and trailing spaces')
			.matches(/^[a-zA-Z0-9]+$/, 'Input should only contain letters or numbers')
			.strict(true)
			.required('Please enter Area First Name'),
		[USER_CODE]: yup
			.string()
			.trim('The Area Code cannot include leading and trailing spaces')
			.matches(/^[a-zA-Z0-9]{1,12}$/, 'Area Code must contain only 1 - 12 letters or numbers')
			.test('no-anti-symbols', 'Area Code cannot contain special symbols', (value) => {
				if (value) {
					return !antiSymbol.test(value);
				}
				return true;
			})
			.strict(true)
			.required('Please enter Area Code'),
		[USER_STATUS]: yup.number(),
	})
	.required();

const AreaGroupsPage = () => {
	const {
		addModalStatus,
		setAddModalStatus,
		editModalStatus,
		setEditModalStatus,
		confirmModalStatus,
		setConfirmModalStatus,
	} = useModalStatus();

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm<TAreaGroupFormProps>({
		resolver: yupResolver(schema),
		defaultValues: {
			[TI_SEARCH_TERM]: '',
			[ID]: 0,
			[FIRST_NAME]: '',
			[USER_CODE]: '',
			[USER_STATUS]: 1,
		},
	});
	const formValues = useWatch({
		control,
		name: [TI_SEARCH_TERM, ID, FIRST_NAME, USER_CODE, USER_STATUS],
	});
	const [searchTerm, id, firstName, userCode, userStatus] = formValues;
	const onFormInvalid = (err: any) => console.error(err);

	const [filteredArea, setFilteredArea] = useState<IAreaGroupRequestBody[]>([]);
	const addPermissionCode = localStorage
		.getItem('permissions')
		?.split(',')
		.includes('ADD_Area_Groups');

	const queryClient = useQueryClient();
	const areaListingQuery = useQuery([AREA_LISTING], getAreas);
	const baseStatusQuery = useQuery([BASE_STATUS], getStatus);
	const invalidateAreaQuery = () => queryClient.invalidateQueries({ queryKey: [AREA_LISTING] });

	const postAreaMutation = useMutation({
		mutationFn: (area: IAreaGroupRequestBody) => postArea(area),
		onSuccess: ({ data: { status, message } }) => {
			invalidateAreaQuery();
			showNoti(status, message, 'success');
			setAddModalStatus(false);
		},
		onError: (err: any) => {
			if (err.code === 'ERR_NETWORK') {
				showNoti('Add Failed', err.message, 'danger');
			} else {
				showNoti(err.response.data.data.status, err.response.data.data.message, 'danger');
			}
		},
	});
	const onAddSubmit = () =>
		postAreaMutation.mutate({
			[FIRST_NAME]: trimInput(firstName),
			[USER_CODE]: trimInput(userCode),
		});

	const putAreaMutation = useMutation({
		mutationFn: (areaId: number) =>
			putArea(areaId, {
				[FIRST_NAME]: trimInput(firstName),
				[USER_CODE]: trimInput(userCode),
				[USER_STATUS]: userStatus,
			}),
		onSuccess: ({ data: { status, message } }) => {
			invalidateAreaQuery();
			showNoti(status, message, 'info');
			setEditModalStatus(false);
		},
		onError: (err: any) => {
			showNoti(err.response.data.data.status, err.response.data.data.message, 'danger');
		},
	});
	const onEditSubmit = () => putAreaMutation.mutate(id);

	const deleteAreaMutation = useMutation({
		mutationFn: (areaId: number) => deleteArea(areaId),
		onSuccess: ({ data: { status, message } }) => {
			invalidateAreaQuery();
			showNoti(status, message, 'info');
			setConfirmModalStatus(false);
		},
		onError: (err: any) => {
			showNoti(err.response.data.data.status, err.response.data.data.message, 'danger');
		},
	});
	const onDeleteSubmit = () => deleteAreaMutation.mutate(id);

	// Filtering area group based on the search input
	useEffect(() => {
		if (areaListingQuery.data !== undefined) {
			setFilteredArea(filterData(areaListingQuery.data, FIRST_NAME, searchTerm));
		}
	}, [areaListingQuery.data, searchTerm]);

	return (
		<PageWrapper title='Area Groups'>
			<Page container='fluid'>
				<PageCard
					hasAddModal={addPermissionCode}
					setModalStatus={setAddModalStatus}
					buttonText='Add Area'
					cardLabelIcon='CustomSignPost'
					cardTitle='Area Group List'
					resetField={reset}>
					<PCSOSearchInput control={control} name={TI_SEARCH_TERM} placeholder='Search Area' />

					{/* Area Group Listing */}
					<div className='row'>
						{areaListingQuery.isLoading && <SpinnerBlack />}
						{areaListingQuery.isError && <ErrorOccured />}
						{areaListingQuery.isSuccess &&
							filteredArea.map((a: any) => (
								<div
									key={a[ID]}
									className='col-12 col-lg-6 col-xxl-3'
									onClick={() => {
										clearErrors();
										setValue(ID, a[ID]);
										setValue(FIRST_NAME, a[FIRST_NAME]);
										setValue(USER_CODE, a[USER_CODE]);
										setValue(USER_STATUS, a[USER_STATUS]);
									}}>
									{areaListingQuery.isFetching === true ? (
										<PCSOCardGray2 />
									) : (
										<PCSOCard
											setModalStatus={setEditModalStatus}
											title={a[FIRST_NAME]}
											userStatus={a[USER_STATUS]}
										/>
									)}
								</div>
							))}
						{areaListingQuery.isSuccess &&
							(areaListingQuery.data?.length === 0 || filteredArea?.length === 0) && (
								<NoResultMsg />
							)}
					</div>
				</PageCard>

				{/* Add Area Group Modal */}
				<ModalAdd
					disableBtn={postAreaMutation.isLoading}
					isStaticBackdrop
					setModalStatus={setAddModalStatus}
					modalId='addAreaGroupModal'
					modalStatus={addModalStatus}
					modalTitle='Add New Area'
					formId='addAreaForm'>
					<form id='addAreaForm' onSubmit={handleSubmit(onAddSubmit, onFormInvalid)}>
						<FormTextInput
							label='Area First Name'
							name={FIRST_NAME}
							control={control}
							errors={errors}
						/>
						<FormTextInput title='Area Code' name={USER_CODE} control={control} errors={errors} />
					</form>
				</ModalAdd>

				{/* Edit Area Group modal */}
				{addPermissionCode === true && (
					<ModalEdit
						disableBtn={putAreaMutation.isLoading}
						isStaticBackdrop
						hasDeleteBtn={true}
						handleDelete={() => {
							setEditModalStatus(false);
							setConfirmModalStatus(true);
						}}
						setModalStatus={setEditModalStatus}
						modalId='editAreaGroupModal'
						modalStatus={editModalStatus}
						modalTitle='Edit Area Information'
						formId='editAreaGroupForm'>
						<form id='editAreaGroupForm' onSubmit={handleSubmit(onEditSubmit, onFormInvalid)}>
							<FormTextInput
								label='Area First Name'
								name={FIRST_NAME}
								control={control}
								errors={errors}
							/>
							<FormTextInput title='Area Code' name={USER_CODE} control={control} errors={errors} />
							<FormSelectInput
								label='Area Group Status'
								name={USER_STATUS}
								control={control}
								query={baseStatusQuery}
								list={selectValues(baseStatusQuery?.data)}
							/>
						</form>
					</ModalEdit>
				)}

				{/* Confirm Deletion Modal */}
				<ModalConfirm
					isLoading={deleteAreaMutation.isLoading}
					disableBtn={deleteAreaMutation.isLoading}
					isStaticBackdrop
					handleDelete={onDeleteSubmit}
					modalStatus={confirmModalStatus}
					setModalStatus={setConfirmModalStatus}
					formId='confirmAreaGroupModalForm'
				/>
			</Page>
		</PageWrapper>
	);
};

export default AreaGroupsPage;
