interface ISummaryDetailProps {
	title?: string;
	amount?: string | number;
	isDraw?: boolean;
	isFetching?: boolean;
}
export const SummaryDetail = ({ title, amount, isDraw, isFetching }: ISummaryDetailProps) => {
	let unit = '';
	if (amount === 1) {
		unit = 'st';
	} else if (amount === 2) {
		unit = 'nd';
	} else if (amount === 3) {
		unit = 'rd';
	}

	return (
		<>
			<div className={isFetching ? 'card-text placeholder-glow w-100' : 'd-flex flex-column w-100'}>
				<p className={isFetching ? 'placeholder col-10 m-0' : 'm-0'}>{title}</p>
				<p className={isFetching ? 'placeholder col-8 m-0' : 'fs-5 fw-bold m-0'}>
					{amount}
					{isDraw && unit}
				</p>
			</div>
		</>
	);
};
