import Select from 'components/bootstrap/forms/Select';
import { PCSOFormGroup } from 'components/pcso/form/PCSOFormGroup';
import { Controller } from 'react-hook-form';

interface IPCSOSelectProps {
	control: any;
	list: [] | { text: string; value: string | number; code?: string | number }[];
	name: string;
	className?: string;
	placeholder?: string;
	disabled?: boolean;
}
export const PCSOSelect = ({
	control,
	list,
	name,
	className = '',
	placeholder,
	disabled = false,
}: IPCSOSelectProps) => {
	return (
		<PCSOFormGroup className={`${className}`}>
			<Controller
				name={name}
				control={control}
				render={({ field }) => (
					<Select
						disabled={disabled}
						ariaLabel={name}
						id={name}
						list={list}
						value={field.value}
						onChange={field.onChange}
						placeholder={placeholder}
						className={className}
					/>
				)}
			/>
		</PCSOFormGroup>
	);
};
