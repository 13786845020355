import { ApiRequestConfig } from 'type/api-type';
import { serverApi } from 'api/api';
import { IAreaGroupRequestBody } from 'type/pcso/api-type';

const MAIN_URL = '/area';

// Get all area group user from current login userId
export const getAreas = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/areaGrps`, config).then((res) => res.data.data);

// Add new area group user
export const postArea = (body: IAreaGroupRequestBody, config: ApiRequestConfig = {}) =>
	serverApi.post(`${MAIN_URL}/add`, body, config).then((res) => res.data);

// Update specific area group user information
export const putArea = (id: number, body: IAreaGroupRequestBody, config: ApiRequestConfig = {}) =>
	serverApi.put(`${MAIN_URL}/update?id=${id}`, body, config).then((res) => res.data);

// Delete specific area group user by id
export const deleteArea = (id: number, config: ApiRequestConfig = {}) =>
	serverApi.delete(`${MAIN_URL}/delete?id=${id}`, config).then((res) => res.data);
