import Label from 'components/bootstrap/forms/Label';

type Props = {
	title: string;
	subtitle?: string;
};
export const PCSOLabel = ({ title, subtitle = 'Abbreviation' }: Props) => {
	return (
		<div className='d-flex flex-column w-50' style={{ marginRight: -12 }}>
			<Label className='m-0 fw-semibold'>
				{title}
				<span className='text-danger'>*</span>
			</Label>
			<Label className='m-0 fw-semibold'>({subtitle})</Label>
		</div>
	);
};
