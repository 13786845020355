import FormGroup from 'components/bootstrap/forms/FormGroup';
import Input from 'components/bootstrap/forms/Input';
import InputGroup, { InputGroupText } from 'components/bootstrap/forms/InputGroup';
import Icon from 'components/icon/Icon';
import { Controller } from 'react-hook-form';

interface IPCSOSearchInputProps {
	control: any;
	name: string;
	placeholder: string;
	className?: string;
}
export const PCSOSearchInput = ({
	control,
	name,
	placeholder,
	className,
}: IPCSOSearchInputProps) => {
	return (
		<FormGroup className={`${className} ${className?.includes('mb-') ? '' : 'mb-4'}`}>
			<InputGroup>
				<InputGroupText>
					<Icon icon='Search' size='lg' />
				</InputGroupText>
				<Controller
					name={name}
					control={control}
					render={({ field }) => (
						<Input
							id={name}
							type='search'
							ariaLabel={placeholder}
							placeholder={placeholder}
							{...field}
						/>
					)}
				/>
			</InputGroup>
		</FormGroup>
	);
};
