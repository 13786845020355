import { ErrorMessage } from '@hookform/error-message';
import { FormErrorMsg } from 'components/pcso/FormErrorMsg';
import { PCSOFormGroup } from 'components/pcso/form/PCSOFormGroup';
import { PCSOFormLabel } from 'components/pcso/form/PCSOFormLabel';
import { PCSOInput } from 'components/pcso/form/PCSOInput';
import { PCSOLabel } from 'components/pcso/form/PCSOLabel';

interface FormTextInputProps {
	// if need (Abbreviation) pass title else pass label
	label?: string;
	title?: string;
	name: string;
	errors?: any;
	control: any;
	isRequired?: boolean;
	readOnly?: boolean;
	placeholder?: string;
	value?: any;
	onBlur?: (e: { target: { value: string } }) => void;
	onKeyDown?: (e: { target: { value: string } }) => void;
}

export const FormTextInput = ({
	control,
	name,
	label,
	title,
	errors,
	isRequired,
	readOnly = false,
	placeholder,
	value,
	onBlur,
	onKeyDown,
}: FormTextInputProps) => {
	return (
		<PCSOFormGroup>
			<>
				{label && <PCSOFormLabel isRequired={isRequired} labelText={label} />}
				{title && <PCSOLabel title={title} />}
			</>
			{readOnly ? (
				<div className='w-100'>
					{value ? (
						<PCSOInput
							readOnly
							disabled
							control={control}
							name={name}
							placeholder={placeholder}
							value={value}
						/>
					) : (
						<PCSOInput readOnly disabled control={control} name={name} placeholder={placeholder} />
					)}
				</div>
			) : (
				<div className='w-100'>
					<PCSOInput
						control={control}
						name={name}
						placeholder={placeholder}
						fontFamily='Roboto'
						onBlur={onBlur}
						onKeyDown={onKeyDown}
					/>
					<ErrorMessage
						errors={errors}
						name={name}
						render={({ message }) => <FormErrorMsg msg={message} />}
					/>
				</div>
			)}
		</PCSOFormGroup>
	);
};
