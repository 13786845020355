import { ApiRequestConfig } from 'type/api-type';
import { serverApi } from 'api/api';

const MAIN_URL = '/dshbrd';

// Get consolidated bet entried with specific number
export const getBetEntries = (
	drawDate: string,
	drawTimeId: number,
	num: string,
	siteId?: number,
	config: ApiRequestConfig = {},
) =>
	serverApi
		.get(
			`${MAIN_URL}/betEntries?drawDate=${drawDate}&drawTimeId=${drawTimeId}&num=${num}&siteId=${siteId}`,
			config,
		)
		.then((res) => res.data.data);

// Get cashier information
export const getCashierInfo = (cashierId: number, siteId?: number, config: ApiRequestConfig = {}) =>
	serverApi
		.get(`${MAIN_URL}/cashierInfo?cashierId=${cashierId}&siteId=${siteId}`, config)
		.then((res) => res.data.data);

// Get outlet information
export const getOutletInfo = (cashierId: number, siteId?: number, config: ApiRequestConfig = {}) =>
	serverApi
		.get(`${MAIN_URL}/downlineInfo?cashierId=${cashierId}&siteId=${siteId}`, config)
		.then((res) => res.data.data);

// Get commission details
export const getCommDetails = (siteId?: number, config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/commDetails?siteId=${siteId}`, config).then((res) => res.data.data);

// Get current draw summary
export const getCurDrawSmry = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/curDrawSmry`, config).then((res) => res.data.data);

// Get dashboard summary
export const getDshbrdSumry = (siteId?: number, config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/dshbrdSmry?siteId=${siteId}`, config).then((res) => res.data.data);

// Get per game summary
export const getPerGameSmry = (siteId?: number, config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/perGameSmry?siteId=${siteId}`, config).then((res) => res.data.data);

// Get consolidated and risk bets listing
export const getRiskBets = (
	drawDate: string,
	drawTimeId: number,
	siteId: number,
	gameTypeId: number,
	pageNum: number,
	pageSize: number,
	sortBy: string,
	sortOrd: string,
	config: ApiRequestConfig = {},
) =>
	serverApi
		.get(
			`${MAIN_URL}/riskBets?drawDate=${drawDate}&drawTimeId=${drawTimeId}&siteId=${siteId}&gameTypeId=${gameTypeId}&pageNum=${pageNum}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrd=${sortOrd}`,
			config,
		)
		.then((res) => res.data.data);

// Get cashier sales trend
export const getSalesTrend = (
	drawDate: string,
	siteId: number,
	filterId: number | string,
	config: ApiRequestConfig = {},
) =>
	serverApi
		.get(
			`${MAIN_URL}/salesTrend?drawDate=${drawDate}&siteId=${siteId}&incsales=${filterId}`,
			config,
		)
		.then((res) => res.data.data);

// Get outlet sales trend
export const getOutletTrend = (
	drawDate: string,
	siteId: number,
	filterId: number | string,
	config: ApiRequestConfig = {},
) =>
	serverApi
		.get(
			`${MAIN_URL}/downlineSalesTrend?drawDate=${drawDate}&siteId=${siteId}&incsales=${filterId}`,
			config,
		)
		.then((res) => res.data.data);

// Get winning bets review
export const getWinbetReview = (
	drawDate: string,
	siteId: number,
	drawTimeId: number,
	isConfirmed: boolean,
	config: ApiRequestConfig = {},
) =>
	serverApi
		.get(
			`${MAIN_URL}/winBetReview?drawDate=${drawDate}&siteId=${siteId}&drawTimeId=${drawTimeId}&isConfirmed=${isConfirmed}`,
			config,
		)
		.then((res) => res.data.data);

// Get testing for pagination
export const getTesting = (
	pageNum: number,
	pageSize: number,
	sortBy: string,
	sortOrd: string,
	config: ApiRequestConfig = {},
) =>
	serverApi
		.get(
			`/test/getDraws?pageNum=${pageNum}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrd=${sortOrd}`,
			config,
		)
		.then((res) => res.data.data);
