interface IModalSpinnerProps {
	color?: string;
}
export const SpinnerBlack = ({ color = '#000000' }: IModalSpinnerProps) => {
	return (
		<div
			className='d-flex justify-content-center align-items-center'
			style={{ height: '100%', minHeight: '350px' }}>
			<div className='spinner-border' style={{ height: '50px', width: '50px', color: color }}></div>
		</div>
	);
};
