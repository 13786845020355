import { ApiRequestConfig } from 'type/api-type';
import { serverApi } from 'api/api';

const MAIN_URL = '/base';

// Get area group drop-down list
export const getArea = (statusId?: number, config: ApiRequestConfig = {}) => {
	if (!statusId) {
		return serverApi.get(`${MAIN_URL}/area`, config).then((res) => res.data.data);
	} else {
		return serverApi
			.get(`${MAIN_URL}/area?statusId=${statusId}`, config)
			.then((res) => res.data.data);
	}
};

// Get report cashier filter drop-down list
export const getCashierFilter = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/cashierFilter`, config).then((res) => res.data.data);

// Get today draws drop-down list
export const getDraws = (siteId?: number, config: ApiRequestConfig = {}) => {
	if (siteId) {
		return serverApi.get(`${MAIN_URL}/draws?siteId=${siteId}`, config).then((res) => res.data.data);
	} else {
		return serverApi.get(`${MAIN_URL}/draws?siteId=1`, config).then((res) => res.data.data);
	}
};

// Get available games drop-down list
export const getGames = (siteId?: number, config: ApiRequestConfig = {}) => {
	if (siteId) {
		return serverApi.get(`${MAIN_URL}/games?siteId=${siteId}`, config).then((res) => res.data.data);
	} else {
		return serverApi.get(`${MAIN_URL}/allGames`, config).then((res) => res.data.data);
	}
};

// Get location drop-down list by areaGroupId
export const getLocation = (
	areaGroupId?: number | string,
	statusId?: number | string,
	config: ApiRequestConfig = {},
) => {
	if (!areaGroupId) {
		return serverApi.get(`${MAIN_URL}/location`, config).then((res) => res.data.data);
	} else {
		return serverApi
			.get(`${MAIN_URL}/location?areaGroupId=${areaGroupId}&statusId=${statusId}`, config)
			.then((res) => res.data.data);
	}
};

// Get outlet drop-down list by locationId
export const getOutlet = (
	locationId: number | string | undefined,
	statusId?: number | string,
	config: ApiRequestConfig = {},
) => {
	if (!locationId) {
		return serverApi.get(`${MAIN_URL}/outlet`, config).then((res) => res.data.data);
	} else {
		return serverApi
			.get(`${MAIN_URL}/outlet?locationId=${locationId}&statusId=${statusId}`, config)
			.then((res) => res.data.data);
	}
};

// Get package drop-down list
export const getPackage = (userId?: number, config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/package?userId=${userId}`, config).then((res) => res.data.data);

// Get cashier sales trend filter drop-down list
export const getSalesTrendFilter = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/salesTrendFilter`, config).then((res) => res.data);

// Get status drop-down list
export const getStatus = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/status`, config).then((res) => res.data.data);

// Get user group drop-down list
export const getUsrGrp = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/usrGrp`, config).then((res) => res.data.data);

// Get bet type drop-down list
export const getBetType = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/betType`, config).then((res) => res.data.data);

// Get bet type drop-down list
export const getHotNumberLevel = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/hotNumberLevel`, config).then((res) => res.data.data);

// Get cashier drop-down list in users page
export const getCashier = (
	outletId: number | string,
	statusId: number | string,
	config: ApiRequestConfig = {},
) => {
	return serverApi
		.get(`${MAIN_URL}/cashier?outletId=${outletId}&statusId=${statusId}`, config)
		.then((res) => res.data.data);
};

// Get sites type drop-down list [pcso/stl]
export const getSitesType = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/sites`, config).then((res) => res.data.data);
// Get Module list
export const getRefPermission = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/refPermission/allRefPermission`, config).then((res) => res.data.data);

// Get User Group List
export const getUserGroup = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/userGroupPermission`, config).then((res) => res.data.data);

// Get Request Type List
export const getRequestType = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/requestType`, config).then((res) => res.data.data);

// Get Approval Type List
export const getApprovalType = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/approvalType`, config).then((res) => res.data.data);

// Get Cashier List
export const getAllCashier = (
	uplineId: number | string | undefined,
	statusId: number | string,
	config: ApiRequestConfig = {},
) =>
	serverApi
		.get(`${MAIN_URL}/getAllCashier?uplineId=${uplineId}&statusId=${statusId}`, config)
		.then((res) => res.data.data);

// Check third party
export const getCheckThirdPartyApiConfig = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/checkThirdPartyApiConfig`, config).then((res) => res.data.data);

// Test third party (ONLY FOR TESTING PURPOSES)
export const getTestCheckThirdPartyApiConfig = (config: ApiRequestConfig = {}) =>
	serverApi.get(`test/checkThirdPartyApiConfig`, config).then((res) => res.data.data);

// Get Draw Result Entry Count
export const getDrawResultEntryCount = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/drawResultEntryCount`, config).then((res) => res.data.data);

// Get System Remain Fund
export const getSystemRemainFund = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/systemRemainFund`, config).then((res) => res.data.data);

// Get Ticket Status
export const getTicketStatus = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/ticketStatus`, config).then((res) => res.data.data);

// Get Forbid Ticket Status
export const getForbidTicketStatus = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/forbidTicketStatus`, config).then((res) => res.data.data);

export const getSeamlessWalletRequestStatus = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/seamlessWalletRequestStatus`, config).then((res) => res.data.data);

export const getSeamlessWalletRequestType = (config: ApiRequestConfig = {}) =>
	serverApi.get(`${MAIN_URL}/seamlessWalletRequestType`, config).then((res) => res.data.data);
