import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomSignPost = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		height='1em'
		viewBox='0 0 24 24'
		width='1em'
		fill='currentColor'
		className='svg-icon'
		{...props}>
		<path fill='none' d='M0 0h24v24H0z' />
		<path d='M13 10h5l3-3-3-3h-5V2h-2v2H4v6h7v2H6l-3 3 3 3h5v4h2v-4h7v-6h-7v-2zM6 6h11.17l1 1-1 1H6V6zm12 10H6.83l-1-1 1-1H18v2z' />
	</svg>
);
export default SvgCustomSignPost;
