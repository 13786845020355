export const API_DATA_MAPPING = {
	ID: 'id' as 'id',
	AREA_GROUP_ID: 'areaGroupId' as 'areaGroupId',
	STATUS_ID: 'statusId' as 'statusId',
	IS_ACTIVE: 'isActive' as 'isActive',
	FIRST_NAME: 'firstName' as 'firstName',
	USER_NAME: 'userName' as 'userName',
	USER_CODE: 'userCode' as 'userCode',
	USER_AREA_ID: 'userAreaId' as 'userAreaId',
	USER_LOCATION_ID: 'userLocationId' as 'userLocationId',
	USER_OUTLET_ID: 'userOutletId' as 'userOutletId',
	USER_STATUS: 'userStatus' as 'userStatus',
	AREA: 'area' as 'area',
	LOCATION: 'location' as 'location',
	OUTLET: 'outlet' as 'outlet',
	COMMISSION: 'commission' as 'commission',
	PASSWORD: 'password' as 'password',
	CONFIRM_PASSWORD: 'confirmPassword' as 'confirmPassword',
	MOBILE_NO: 'mobileNo' as 'mobileNo',
	DRAW_DATE: 'drawDate' as 'drawDate',
	DRAW_TIME_ID: 'drawTimeId' as 'drawTimeId',
	CREDIT_LIMIT: 'creditLimit' as 'creditLimit',
	BALANCE_LIMIT: 'balanceLimit' as 'balanceLimit',
	TOP_UP: 'topUp' as 'topUp',
	DRAW_GAME_ID: 'id' as 'id',
	DRAW_GAME_NAME: 'name' as 'name',
	START_DRAW_DATE: 'startDate' as 'startDate',
	START_DRAW_TIME: 'cronStartDrawDate' as 'cronStartDrawDate',
	END_DRAW_TIME: 'cronEndDrawDate' as 'cronEndDrawDate',
	PUBLISH_TIME: 'cronPublishDate' as 'cronPublishDate',
	RESULT_TIME: 'cronResultDate' as 'cronResultDate',
	DRAW_DESCRIPTION: 'description' as 'description',
	PRIZE_AMOUNT: 'prizeAmount' as 'prizeAmount',
	SALES_COMI_RATE: 'salesComiRate' as 'salesComiRate',
	PRIZE_AMOUNT_SWER3: 'prizeAmountSWER3' as 'prizeAmountSWER3',
	PRIZE_AMOUNT_EZ2: 'prizeAmountEZ2' as 'prizeAmountEZ2',
	PRIZE_AMOUNT_RG7: 'prizeAmountRG7' as 'prizeAmountRG7',
	SALES_COMI_RATE_SWER3: 'salesComiRateSWER3' as 'salesComiRateSWER3',
	SALES_COMI_RATE_EZ2: 'salesComiRateEZ2' as 'salesComiRateEZ2',
	SALES_COMI_RATE_RG7: 'salesComiRateRG7' as 'salesComiRateRG7',
	PACKAGE_NAME: 'packageName' as 'packageName',
	PACKAGE_GAME_NAME: 'packageGameName' as 'packageGameName',
	PACKAGE_ID: 'packageId' as 'packageId',
	PACKAGE_ID_DELETED: 'packageIdDeleted' as 'packageIdDeleted',
	PACKAGE: 'package' as 'package',
	GENERAL_BET_TYPE_ID: 'refBetTypeIdGeneral' as 'refBetTypeIDGeneral',
	BET_TYPE_ID: 'refBetTypeId' as 'refBetTypeID',
	BET_TYPE_ID_EDIT: 'refBetTypeIDEdit' as 'refBetTypeIDEdit',
	GENERAL_BET_TYPE_ID_EDIT: 'refBetTypeIDEditGeneral' as 'refBetTypeIDEditGeneral',
	BET_NUMBER: 'betNumber' as 'betNumber',
	GENERAL_GAME_TYPE_ID: 'refGameTypeIdGeneral' as 'refGameTypeIdGeneral',
	GAME_TYPE_ID: 'refGameTypeId' as 'refGameTypeId',
	GENERAL_HOT_NUMBER_ID: 'refHotNumberIdGeneral' as 'refHotNumberIdGeneral',
	HOT_NUMBER_ID: 'refHotNumberId' as 'refHotNumberId',
	HOT_NUMBER_DETAIL_ID: 'refHotNumberDetailId' as 'refHotNumberDetailId',
	HOT_NUMBER_MINIMUM: 'minBetAmount' as 'minBetAmount',
	HOT_NUMBER_TOTAL: 'totalBetAmount' as 'totalBetAmount',
	HOT_NUMBER_PRIZE: 'prizeAmount' as 'prizeAmount',
	ISACTIVATED: 'isActivated' as 'isActivated',
	TICKET_ID: 'ticketId' as 'ticketId',
	TICKET_NO: 'ticketNo' as 'ticketNo',
	LATITUDE: 'latitude' as 'latitude',
	LONGITUDE: 'longitude' as 'longitude',
	CASHIER: 'cashier' as 'cashier',
	LOGIN_USER_ID: 'id' as 'id',
	MIN_BET_AMOUNT: 'minimumBetAmount' as 'minimumBetAmount',
	MAX_BET_AMOUNT: 'maximumBetAmount' as 'maximumBetAmount',
	DRAWRESULT_ID: 'drawResultId' as 'drawResultId',
	DRAWDATEE: 'drawDatee' as 'drawDatee',
	UPLINE: 'userParent' as 'userParent',
	USER_PARENT_ID: 'userParentId' as 'userParentId',
	UPLINE_SHARE_COMM: 'uplineShareCommRate' as 'uplineShareCommRate',
	MIN_BET: 'minBetAmount' as 'minBetAmount',
	MAX_BET: 'maxBetAmount' as 'maxBetAmount',
	TYPE: 'Type' as 'Type',
	PERMISSION: 'permissions' as 'permissions',
	REF_STATUS: 'refStatus' as 'refStatus',
	USER_GROUP_ID: 'userGroupId' as 'userGroupId',
	WORKER_AREA_ID: 'areaId' as 'areaId',
	APPROVAL_REQUEST_ID: 'reqNo' as 'reqNo',
	APPROVAL_TYPE_CODE: 'refStatusCode' as 'refStatusCode',
	DISPLAY_PACKAGE_NAME: 'displayPackageName' as 'displayPackageName',
	IS_FIRST_LINE: 'isFirstLine' as 'isFirstLine',
	MERCHANT_ID: 'id' as 'id',
	MERCHANT_CODE: 'code' as 'code',
	MERCHANT_NAME: 'merchantUsername' as 'merchantUsername',
	MERCHANT_DESCRIPTION: 'description' as 'description',
	MERCHANT_EMAIL: 'email' as 'email',
	IPV4: 'whitelistIps' as 'whitelistIps',
	RETURN_URL: 'returnUrl' as 'returnUrl',
	CREATED_BY: 'createBy' as 'createBy',
	CREATED_DATE_TIME: 'createDate' as 'createDate',
	UPDATE_BY: 'updateBy' as 'updateBy',
	UPDATE_DATE_TIME: 'updateDate' as 'updateDate',
	MERCHANT_STATUS: 'userStatus' as 'userStatus',
	REF_PLATFORM_ID: 'refPlatformId' as 'refPlatformId',
	IS_THIRD_PARTY_PLAYER: 'isThirdPartyPlayer' as 'isThirdPartyPlayer',
	USERNAME: 'username' as 'username',
	TOP_UP_DATE_TIME: 'topUpDate' as 'topUpDate',
	CREDIT_BALANCE_TOP_UP_AMOUNT: 'amount' as 'amount',
	FORBID_STATUS: 'forbidStatus' as 'forbidStatus',
	PAGE_NUM: 'pageNum' as 'pageNum',
	ALL_REQUEST_ID: 'allReqId' as 'allReqId',
	SEAMLESS_WALLET_ORDER_ID: 'orderId' as 'orderId',
	SEAMLESS_WALLET_REQUEST_TYPE: 'requestTypeCode' as 'requestTypeCode',
	END_DRAW_DATE: 'endDate' as 'endDate',
	STATUS: 'status' as 'status',
	ACTION: 'action' as 'action',
	SEAMLESS_WALLET_TOGGLE: 'seamlessWalletToggle' as 'seamlessWalletToggle',
	SEAMLESS_WALLET_CALLBACK_URL: 'seamlessCallbackURL' as 'seamlessCallbackURL',
};

export const QUERY_KEYS = {
	VERSION: 'version' as 'version',
	BASE_AREA: 'baseArea' as 'baseArea',
	BASE_AREA_MODAL: 'baseAreaModal' as 'baseAreaModal',
	BASE_LOCATION: 'baseLocation' as 'baseLocation',
	BASE_LOCATION_MODAL: 'baseLocationModal' as 'baseLocationModal',
	BASE_OUTLET: 'baseOutlet' as 'baseOutlet',
	BASE_OUTLET_MODAL: 'baseOutletModal' as 'baseOutletModal',
	BASE_CASHIER_MODAL: 'baseCashierModal' as 'baseCashierModal',
	BASE_PACKAGE: 'basePackage' as 'basePackage',
	BASE_USER: 'baseUser' as 'baseUser',
	BASE_DRAW: 'baseDraw' as 'baseDraw',
	BASE_GAME: 'baseGame' as 'baseGame',
	BASE_HOT_NUMBER_LEVEL: 'level' as 'level',
	BASE_STATUS: 'baseStatus' as 'baseStatus',
	BASE_CASHIER_FILTER: 'baseCashierFilter' as 'baseCashierFilter',
	BASE_SALES_TREND_FILTER: 'baseSalesTrendFilter' as 'baseSalesTrendFilter',
	BASE_BET_TYPE: 'baseBetType' as 'baseBetType',
	BASE_SITES: 'baseSites' as 'baseSites',
	BASE_REQUEST_TYPE: 'baseRequestType' as 'baseRequestType',
	BASE_APPROVAL_TYPE: 'baseApprovalType' as 'baseApprovalType',
	BASE_CASHIER: 'baseAllCashier' as 'baseAllCashier',
	AREA_LISTING: 'areaListing' as 'areaListing',
	LOCATION_LISTING: 'locationListing' as 'locationListing',
	OUTLET_LISTING: 'outletListing' as 'outletListing',
	USER_LISTING: 'userListing' as 'userListing',
	PACKAGE_LISTING: 'packageListing' as 'packageListing',
	TICKET_LISTING: 'ticketListing' as 'ticketListing',
	STRIKE_TICKET_LISTING: 'strikeTicketListing' as 'strikeTicketListing',
	REVERTDRAW_LISTING: 'revertDrawListing' as 'revertDrawListing',
	DASHBOARD_SUMRY: 'dshbrdSumry' as 'dshbrdSumry',
	DASHBOARD_PER_GAME_SUMRY: 'perGameSmry' as 'perGameSmry',
	DASHBOARD_SALES_TREND: 'salesTrend' as 'salesTrend',
	DASHBOARD_OUTLET_TREND: 'outletTrend' as 'outletTrend',
	DASHBOARD_OUTLET_INFO: 'outletInfo' as 'outletInfo',
	DASHBOARD_CASHIER_INFO: 'cashierInfo' as 'cashierInfo',
	DASHBOARD_COMM_DETAILS: 'commDetails' as 'commDetails',
	DASHBOARD_RISK_BETS: 'riskBets' as 'riskBets',
	DASHBOARD_WIN_BETS_REVIEW: 'WinbetReview' as 'WinbetReview',
	CONFIG_HOT_NUMBER_LEVEL: 'hotNum' as 'hotNum',
	TXN_GAME_TYPE_ID: 'gameTypeId' as 'gameTypeId',
	TXN_WITH_GROSS: 'withGross' as 'withGross',
	TXN_UPDATE: 'update' as 'update',
	MIN_MAX_AMOUNT: 'minMaxAmount' as 'minMaxAmount',
	WORKER_LISTING: 'workerListing' as 'workerListing',
	PERMISSION_LISTING: 'refPermission' as 'refPermission',
	USER_GROUP_LISTING: 'userGroupPermission' as 'userGroupPermission',
	MERCHANT_LISTING: 'merchantListing' as 'merchantListing',
	SYSTEM_ADMIN_LISTING: 'systemAdminListing' as 'systemAdminListing',
	FORBIDDEN_STATUS: 'forbiddenStatus' as 'forbiddenStatus',
	BASE_FORBID_STATUS: 'baseForbidStatus' as 'baseForbidStatus',
	LATE_BET_LISTING: 'lateBetListing' as 'lateBetListing',
	SEAMLESS_WALLET_REQUEST_STATUS: 'seamlessWalletRequestStatus' as 'seamlessWalletRequestStatus',
	SEAMLESS_WALLET_REQUEST_ACTION: 'seamlessWalletRequestAction' as 'seamlessWalletRequestAction',
};

export const TOP_INPUTS = {
	TI_SEARCH_TERM: 'searchTerm' as 'searchTerm',
	TI_SEARCH_REQUEST_ID: 'reqNo' as 'reqNo',
	TI_SEARCH_APPROVER_NAME: 'approverName' as 'approverName',
	TI_AREA_GROUP_ID: 'tiAreaGroupId' as 'tiAreaGroupId',
	TI_LOCATION_ID: 'tiLocationId' as 'tiLocationId',
	TI_OUTLET_ID: 'tiOutletId' as 'tiOutletId',
	TI_CASHIER_ID: 'tiCashierId' as 'tiCashierId',
	TI_USER_GROUP_ID: 'tiUserGroupId' as 'tiUserGroupId',
	TI_STATUS: 'tiStatus' as 'tiStatus',
	TI_GAMETYPE_ID: 'tiGameTypeId' as 'tiGameTypeId',
	TI_CASHIER_FILTER: 'tiCashierFilter' as 'tiCashierFilter',
	TI_DRAW: 'tiDraw' as 'tiDraw',
	TI_BET_TYPE: 'tiBetType' as 'tiBetType',
	TI_APPROVAL_STATUS: 'status' as 'status',
	TI_APPROVAL_REQUEST_ID: 'requestId' as 'requestId',
	TI_APPROVAL_REQUEST_TYPE: 'requestType' as 'requestType',
	TI_SEARCH_MERCHANT_CODE: 'tiCode' as 'tiCode',
	TI_SEARCH_MERCHANT_NAME: 'tiMerchantUsername' as 'tiMerchantUsername',
	TI_SEARCH_MERCHANT_DESCRIPTION: 'tiDescription' as 'tiDescription',
	TI_SEARCH_MERCHANT_EMAIL: 'tiEmail' as 'tiEmail',
	TI_START_DATE: 'startDate' as 'startDate',
	TI_END_DATE: 'endDate' as 'endDate',
	TI_DATE: 'date' as 'date',
	TI_SEARCH_AGENT_NAME: 'agentFirstName' as 'agentFirstName',
	TI_REPORT_TYPE: 'rptType' as 'rptType',
	TI_SEAMLESS_ORDER_ID: 'orderId' as 'orderId',
	TI_SEAMLESS_STATUS: 'seamlessStatus' as 'seamlessStatus',
	TI_SEAMLESS_REQUEST_TYPE: 'seamlessRequestType' as 'seamlessRequestType',
};

export const PLACEHOLDER_BG = '#E9ECEF';
