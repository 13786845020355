import Button from 'components/bootstrap/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'components/bootstrap/Modal';
import Icon from 'components/icon/Icon';
import { ReactNode } from 'react';

interface IModalEditProps {
	setModalStatus: (status: boolean) => void;
	children: ReactNode;
	modalStatus: boolean;
	modalTitle: string;
	modalId: string;
	hasClearUUID?: boolean;
	formId?: string;
	handleDelete?: (id: string) => void;
	hasDeleteBtn?: boolean;
	isDirty?: boolean;
	isStaticBackdrop?: boolean;
	disableBtn?: boolean;
}
export const ModalEdit = ({
	setModalStatus,
	children,
	modalStatus,
	modalTitle,
	modalId,
	hasClearUUID = false,
	formId,
	handleDelete,
	hasDeleteBtn = false,
	isDirty,
	isStaticBackdrop,
	disableBtn = false,
}: IModalEditProps) => {
	return (
		<Modal
			isStaticBackdrop={isStaticBackdrop}
			isAnimation={false}
			id={modalId}
			setIsOpen={setModalStatus}
			isScrollable={true}
			isOpen={modalStatus}
			isCentered={true}
			titleId={modalTitle}>
			<ModalHeader className='bg-info text-white'>
				<ModalTitle id={modalTitle} className='fs-4'>
					{modalTitle}
				</ModalTitle>
				<Icon icon='Cancel' size='2x' onClick={() => setModalStatus(false)} />
			</ModalHeader>
			<ModalBody className='d-flex flex-column'>{children}</ModalBody>
			<ModalFooter>
				{hasDeleteBtn && (
					<Button type='submit' color='danger' icon='Delete' onClick={handleDelete}>
						Delete
					</Button>
				)}
				{hasClearUUID && (
					<Button color='warning' icon='Delete'>
						Clear UUID
					</Button>
				)}
				<Button
					isDisable={disableBtn}
					type='submit'
					color='info'
					icon='RadioButtonChecked'
					form={formId}>
					Save
				</Button>
				<Button color='light' icon='Block' onClick={() => setModalStatus(false)}>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};
