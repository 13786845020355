import Button from 'components/bootstrap/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'components/bootstrap/Modal';
import Icon from 'components/icon/Icon';
import { ReactNode } from 'react';

interface IModalAddProps {
	setModalStatus: (status: boolean) => void;
	children: ReactNode;
	modalStatus: boolean;
	modalTitle: string;
	modalId: string;
	formId?: string;
	isStaticBackdrop?: boolean;
	disableBtn?: boolean;
	isFund?: boolean;
}
export const ModalAdd = ({
	setModalStatus,
	children,
	modalStatus,
	modalTitle,
	modalId,
	formId,
	isStaticBackdrop,
	disableBtn = true,
	isFund = false,
}: IModalAddProps) => {
	return (
		<Modal
			isScrollable={true}
			isAnimation={false}
			id={modalId}
			setIsOpen={setModalStatus}
			isOpen={modalStatus}
			isCentered={true}
			titleId={modalTitle}
			isStaticBackdrop={isStaticBackdrop}>
			<ModalHeader className='bg-info text-white'>
				<ModalTitle id={modalTitle} className='fs-4'>
					{modalTitle}
				</ModalTitle>
				<Icon icon='Cancel' size='2x' onClick={() => setModalStatus(false)} />
			</ModalHeader>
			<ModalBody className='d-flex flex-column'>{children}</ModalBody>
			<ModalFooter>
				<Button
					isDisable={disableBtn}
					form={formId}
					type='submit'
					color='info'
					icon='RadioButtonChecked'>
					{isFund ? 'Confirm' : 'Add'}
				</Button>
				<Button color='light' icon='Block' onClick={() => setModalStatus(false)}>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};
