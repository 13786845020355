export const generalPagesMenu = {
	login: {
		id: 'login',
		text: 'Login',
		path: '/',
		icon: 'Login',
	},
	firstTimeLogin: {
		id: 'firstTimeLogin',
		text: 'First Time Login',
		path: '/first-time-login/',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: '/auth-pages/sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: '/auth-pages/404/',
		icon: 'ReportGmailerrorred',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	usersTransactionHistory: {
		id: 'usersTransactionHistory',
		text: 'Users Transaction History',
		path: '/users-transaction-history/',
		icon: 'PeopleOutline',
	},
};

export const pcsoSidebarMenu = {
	menu: {
		id: 'menu',
		text: 'MENU',
	},
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/dashboard/',
		icon: 'Dashboard',
	},
	transactions: {
		id: 'transactions',
		text: 'Transactions',
		path: '/transactions/',
		icon: 'CustomReceipt',
	},
	reports: {
		id: 'reports',
		text: 'Reports',
		path: '/reports/',
		icon: 'BarChart',
	},
	adjustments: {
		id: 'adjustments',
		text: 'Adjustments',
		path: '/adjustments/',
		icon: 'Tune',
	},
	'approval center': {
		id: 'approval',
		text: 'Approval Center',
		path: '/approval-center/',
		icon: 'Approval',
	},
};

export const pcsoSidebarSettings = {
	settings: {
		id: 'settings',
		text: 'SETTINGS',
	},
	merchants: {
		id: 'merchants',
		text: 'Merchants',
		path: '/merchants/',
		icon: 'Store',
	},
	areagroups: {
		id: 'area-groups',
		text: 'Area Groups',
		path: '/area-groups/',
		icon: 'CustomSignPost',
	},
	arealocations: {
		id: 'area-locations',
		text: 'Area Locations',
		path: '/area-locations/',
		icon: 'CustomSignPost',
	},
	outlets: {
		id: 'outlets',
		text: 'Outlets',
		path: '/outlets/',
		icon: 'LocationOn/',
	},
	users: {
		id: 'users',
		text: 'Users',
		path: '/users/',
		icon: 'PeopleOutline',
	},
	players: {
		id: 'players',
		text: 'Players',
		path: '/players/',
		icon: 'Person',
	},
	workers: {
		id: 'workers',
		text: 'Workers',
		path: '/workers/',
		icon: 'Work',
	},
	configurations: {
		id: 'configurations',
		text: 'Configurations',
		path: '/configurations/',
		icon: 'Settings',
	},
	integrationmanagement: {
		id: 'integration-management',
		text: 'Integration Management',
		path: '/integration-management/',
		icon: 'SettingsInputComponent',
	},
};

export const pcsoRootSidebarSettings = {
	settings: {
		id: 'settings',
		text: 'SETTINGS',
	},
	configurations: {
		id: 'configurations',
		text: 'Configurations',
		path: '/configurations/',
		icon: 'Settings',
	},
};

export const pcsoSidebarCurrentDraw = {
	currentDraw: {
		id: 'currentDrawSummary',
		text: 'CURRENT DRAW SUMMARY',
	},
};

export const summaryPageTopMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/dashboard',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const demoPagesMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: '',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404/',
		icon: 'ReportGmailerrorred',
	},
};

export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
